<template>
    <div class="max_div">
        <div class="top_div">
            <div class="block_div">
                <el-row :gutter="20">
                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                设备账户
                            </div>
                            <div class="data_div">
                                {{this.report.num}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="input_div">
                <el-input v-model="inputData" placeholder="账户名搜索" :size="tabsize" style="width:15%"></el-input>
                <el-input v-model="inputData1" placeholder="SN码" :size="tabsize" style="width:15%;margin-left:1%"></el-input>
                <el-button type="success" :size="tabsize" style="margin-left:1%" icon="el-icon-search" @click="getList">搜索</el-button>
            </div>
        </div>
        <div class="center_div">
            <el-table :data="tableData" border stripe :size="tabsize" style="width:100%;overflow:auto" >+
                    <el-table-column label="账户名" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.cname}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="性别" prop='code' align='center' >
                        <template slot-scope="scope">
                            <span>{{ scope.row.sex | typeFilter(that)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="年龄" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ formatAge(scope.row.age) | apkUpdate }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="城市" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.address ? scope.row.address:'- -'}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="联系方式" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.phone }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="档案数" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.num }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="绑定设备" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.sn}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="绑定时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindtime | tick2ymd}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="账户" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.qdname ? scope.row.qdname:'- -'}}</span>
                        </template>
                    </el-table-column>
            
                    <!-- <el-table-column label="操作" prop='code' align='center' width="200">
                        <template slot-scope="scope">
                            <div class="button_list_div">
                                <el-button :size="tabsize" icon="el-icon-edit"  type="primary"
                                 @click.native.prevent="handlelook(scope.$index,  scope.row)">查看</el-button>
                            </div> 
                        </template>
                    </el-table-column> -->
            </el-table>
        </div>
        <div class="footer_div">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.length">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { getBaseUrl } from '../../../requset/http'
    export default {
        // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
              this.$setTableHeightMax()
              return this.$store.state.tabsize
          }
      },
      mounted(){
        this.getDictInfo()
        this.getReport()
        this.getList()
      },
      data(){
        return{
            user:{},
            sort: "",
            order: "desc",
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: "", //总页数
            topDataList:[],
            dialogTitle:'',
            dialogWidth:'',
            dialogType:'',
            inputData:'',
            inputData1:'',
            tableData:[],
            report:[],
            marketData: undefined,
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: '', //总页数
            order: '',
            sort: '',
            dictData:[],
            that:this
        }
      },
    created() {
            this.user = JSON.parse(sessionStorage.getItem("userInfo"))
    },

      filters:{
            typeFilter:function(type,that){
                type += ''
                for(let i=0;i<that.dictData.length;i++){
                    if(type == that.dictData[i].k){
                        return that.dictData[i].v
                    }
                }
                return '未知'
            }
      },
      methods:{
        getList(){
            this.$api.equiUser.getList({
                'jPage.pageNum': this.currentPage, //当前页码
                'jPage.pageSize': this.pageSize, //当前页面大小
                'jPage.sort': this.sort, //排序字段
                'jPage.order': this.order, //排序方式
                'cname':this.inputData,
                'sn':this.inputData1
            }).then((res) =>{
                if(res.status == 1){
                    this.tableData = res.obj.list
                }
            }).catch((error)=>{
                console.log(error);
            })
        },
        getDictInfo(){
            this.$api.vipuser.getinfo({
                dtype:'yj_sex'
            }).then((res) =>{
                this.dictData = res.obj
            }).catch((error)=>{
                console.log(error);
            })
        },
        getReport(){
            this.$api.equiUser.getReport({

            }).then((res) =>{
                this.report = res.obj
            }).catch((error)=>{
                console.log(error);
            })
        },
        addTableDataOk(){
            this.getList()
        },
        dialogClick(type){
            this.dialogType = 0
            if(type == 1){
                this.dialogTitle = '批量导入发货清单'
                this.dialogWidth = '550px',
                this.dialogType = type
            }else if(type == 2){
                this.dialogTitle = '批量入库清单'
                this.dialogWidth = '550px',
                this.dialogType = type
            }
        },
        jumpPage(type){
            
        },
        returnFile(){
            window.open(getBaseUrl()+'/pc/dev/v1/exportScr')
        },
        // 分页大小
        handleSizeChange(val) {
            this.loading = true
            this.pageSize = val
            this.currentPage = 1 // 选择分页大小后跳转第一页
            this.getList()
        },
        // 第几页
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val
            this.getList()
        },
        closeDia(){
            this.dialogType = 0
        },
        handlelook(index, row) {
            this.$router.push({
                path: "./share",
                query: { kiid: row.id ,birthdata:row.birthdata},
            });
        },
        formatAge(val) {
                return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },
      }
    }
</script>

<style scoped>
    .max_div{
        position: relative;
        width: 98%;
        height: 98%;
        margin: 1%;
    }
    .block_div{
        width: 100%;
        height: 8vh;
    }
    .input_div{
        margin-top: 1%;
        align-items: center;
    }
    .block{
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .el-row{
        width: 100%;
        height: 100%;
    }

    .el-col{
        height: 100%;
    }
    .data_div{
        text-align: center;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .el-table{
        height: 100%;
    }
    /** 解决el-table放大之后多一条线*/
    .el-table::before{
        z-index: inherit;
    }
    .center_div{
        margin-top: 1%;
    }
   

</style>